import React, { useState, useEffect, useRef } from 'react';

import MUIDataTable from 'mui-datatables';
import { Navigate, useNavigate, Link as RouterLink } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
  CardContent,
  Grid,
  Box,
  FormControl,
  Select,
  InputLabel,
  OutlinedInput,
  MenuItem
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import {isAuthenticated, roleId} from '../../../utils/session'
import LoadingModal from '../../../components/settings/LoadingModal';

import DialogModal from '../../../components/settings/DialogModal';
import { showError, showSucess } from '../../../utils/toast';
import {apiUrl, apiCallSecureGet, apiPostSecure, apiCallSecureDelete} from '../../../utils/api'
import AddCategoryModal from './modals'
import CategoryCard from './cards'

// components
import Page from '../../../components/Page';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const CategoryPage = () => {


  const [loadData, setLoadData] = useState(true);
  const [rows, setRows] = useState([]);
  const [categories, setCategories] = useState([]);

  const [deleteData, setDeleteData] = useState(null)
  const [selectedCategory, setSelectedCategory] = React.useState(null);

  const editData = useRef(null)
  const categoryRef = useRef(null)
  const locationList = useRef([])

  const [showDataAdd, setShowDataAdd] = useState(false)

  const [loadingTitle, setLoadingTitle] = useState(null);

  const roleIdName = useRef(roleId());

  const onCancelDelete = () => {
    setDeleteData(null);
  }

  const onDeleteShow = (data) => {
    console.log('onDeleteShow', data);
    setDeleteData(data)
  }

  const onEditShow = (data) => {
    console.log('onEditShow', data);
    editData.current = data;
    setShowDataAdd(true);
  }

  const onDelete = (data) => {
    const id = data.id;
    setDeleteData(null);
    apiCallSecureDelete(
      apiUrl.subCategory, id,
      (response) => {
          showSucess(response.msg);
          setLoadData(true);
      },
      (error) => {
        showError(error||'Error');
        console.log('loadBusses', error||'Error');
      }
    )
  }
  
  const onAddListener = (status, message) => {
    if(status === 1) {
      setLoadingTitle(null);
      showSucess(message);
      setLoadData(true)
    }else if(status === 2) {
      setLoadingTitle(null);
      showError(message)
    }else {
      setLoadingTitle(message);
    }
  }

  const onChangeCategory = (event) => {
      setSelectedCategory(event.target.value);
  };

  const onShowAddData = () => {
    editData.current = null;
    setShowDataAdd(true);
  }

  const GetHeader = () => {
      return(
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Stack direction='column' >
            <Typography variant='h6' color="text.secondary" sx={{ms: 1}} >
              Sub Categories
            </Typography>
            <Box sx={{width: '300px', mt: 2}}>
              <FormControl sx={{width: '300px'}}>
                <InputLabel id="demo-multiple-checkbox-label">Select Category</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={selectedCategory}
                    onChange={onChangeCategory}
                    input={<OutlinedInput label="Select location" />}
                    MenuProps={MenuProps}
                    >
                      {categories}
                  </Select>
              </FormControl>
            </Box>
          </Stack>
          <Button variant="outlined" startIcon={<AddIcon />} onClick={() => onShowAddData()}>
            Add 
          </Button>
        </Stack>
      )
  }

  useEffect(() => {
    
    if(selectedCategory == null) {
      return
    }
    
    apiCallSecureGet(`${apiUrl.subCategory}?id=${selectedCategory}`, 
      (response) => {
          const itemList = response.data.map((item) => (
              <Grid item xs={12} md={6} lg={4}>
                  <CategoryCard data={item} role={roleIdName}  onEdit={onEditShow} onDelete={onDeleteShow}/>
              </Grid>
          ));
          setRows(itemList)
      },
      (error) => {
          console.log('error', error);
          showError('Error!', error||'Something went wrong.');
  });

  }, [selectedCategory, loadData] )

  useEffect(() => {
    
    if(!loadData) {
      return
    }
    
    setLoadData(false);
    apiCallSecureGet(apiUrl.category, 
        (response) => {
            const itemList = [];
            itemList.push(<MenuItem value={0}>All Categories</MenuItem>);
            response.data.forEach(item => {
              console.log('item', item);
              itemList.push(<MenuItem value={item.id}>{item.name}</MenuItem>);
            });
            
            console.log('itemList', itemList);
            setCategories(itemList);
            setSelectedCategory(0);

            const arr = [];
            response.data.forEach(item => {
              console.log('item', item);
              arr.push(<MenuItem value={item.id}>{item.name}</MenuItem>);
            });

            categoryRef.current = arr;
            console.log('categoryRef', categoryRef.current)
        },
        (error) => {
            console.log('error', error);
            showError('Error!', error||'Something went wrong.');
    });
    
    apiCallSecureGet(
      apiUrl.locations,
      (response) => {
        console.log('loadLocations', response);
        locationList.current = response.list;
      },
      (error) => {
        showError(error||'Error');
        console.log('loadBusses', error||'Error');
      }
    )
    
}, [loadData])

  return (
      <Page title="Categories">
      <Container>
        <LoadingModal
            title={loadingTitle} />
        <DialogModal
          state={deleteData}
          title='Delete Category?'
          message={`Do you want to delete this Category?`}
          handleclose={onCancelDelete}
          addclickhandler={onDelete}
          buttonlabel="Yes, Delete"
        />   
        <AddCategoryModal
          show={showDataAdd} change={setShowDataAdd} state={editData} categories={categoryRef}  locationList={locationList} onSaveChange={onAddListener} onError={showError} />
        <Card sx={{p:2}} >
          <GetHeader />
        </Card>
        <Grid container spacing={2} sx={{mt: 2}}>
          {rows}          
        </Grid>
      </Container>
    </Page>
    );
}

export default CategoryPage;
