import { useEffect, useState, useRef } from 'react';
import { Outlet, Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc, getDocs, setDoc, doc, onSnapshot } from "firebase/firestore";
import { getDatabase, ref as sRef, set } from "firebase/database";
import useSound from 'use-sound'

import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
// ----------------------------------------------------------------------
import ALERT_TONE from '../../assets/office_phone.mp3'
import {isAuthenticated, hostId, temiId} from '../../utils/session';
import CallDialogModel from './models'

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;
const MAX_TIME_DIFF = 5000;

const DATABASE_URL = 'videoCalls'

const firebaseConfig = {
  apiKey: "AIzaSyChlS1r6aURBE0u3V7DS6Q3CHY78S8LidU",
  authDomain: "miroboticproject.firebaseapp.com",
  databaseURL: "https://miroboticproject.firebaseio.com",
  projectId: "miroboticproject",
  storageBucket: "miroboticproject.appspot.com",
  messagingSenderId: "387151318489",
  appId: "1:387151318489:web:ce680b2b3088870ddc1dc8",
  measurementId: "G-G5RCBZNVQ2"
};


const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const app = initializeApp(firebaseConfig);

// ----------------------------------------------------------------------


export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [incoming, setIncoming] = useState(false);

  const [play, { stop }] = useSound(ALERT_TONE)

  const navigate = useNavigate();

  if(!isAuthenticated()){
    return <Navigate to='/login'  />
  }

  useEffect(() => {
    console.log('incoming', incoming);
    if(incoming) {
      play();
        // audioPlayer.current?.play()
    }else {
      stop();
        // audioPlayer.current?.pause()
    }
  }, [incoming])


  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
