import * as React from 'react';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Edit from '@mui/icons-material/Edit';
import Info from '@mui/icons-material/Info';
import Delete from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility';
import { Grid, Tooltip, IconButton, Stack, Avatar, Box } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import VideoIcon from '../../../../assets/video-player.png'
import {apiUrl} from '../../../../utils/api'

const getText = (text) => {
  if(text) {
    if(text.length > 60) {
      return `${text.substring(0, 60)}...`
    }
    return text;
  }
  return '------'
}

const GetImage = ({data}) => {
    if(!data.image) {
        return null;
    }

    console.log('media', `${apiUrl.contentMedia}${data.image}`);

    return (
        <Box sx={{ backgroundImage: `url(${apiUrl.contentMedia}${data.image})`,
          width: '100%',
          height: '200px',
          border: '0px solid',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat' 
         }}>
           
        </Box>
    )
}

const GetText = ({data}) => {
  return (
      <Box>
          <Typography variant='h6'>{getText(data.name)}</Typography>
          <Typography sx={{height: '60px', width: '90%',}}>{getText(data.description)}</Typography>
          <GetImage data={data} />
      </Box>
  )
}
export default function CategoryCard({data, role, onEdit, onDelete}) {

  const GetActions = () => {

    if(role.current === 'A' || role.current === 'ST') {
      return (
        <Stack 
            spacing={2}
            sx={{width:'100%'}}
            justifyContent="center"
            alignItems="center"
            direction="row"> 
          <Tooltip title="Edit" sx={{m: 2}}>
            <Avatar sx={{ bgcolor: '#46FF46' }}>
              <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => onEdit(data)}>
                <Edit />
              </IconButton>
            </Avatar>            
          </Tooltip>
          <Tooltip title="Delete" sx={{m: 2}}>
            <Avatar sx={{ bgcolor: '#FF4646' }}>
              <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => onDelete(data)}>
                <Delete />
              </IconButton>
            </Avatar>            
          </Tooltip>
        </Stack>
      )
    }
    return null
  }


  return (
    <Card  sx={{ boxShadow: 1}} spacing={2}>
      <CardContent sx={{height: '310px'}}>
        <Box sx={{position: 'relative'}}>
          <Box >
            <GetText data={data} />
          </Box>
        </Box>
      </CardContent>
      <CardActions sx={{rightAlignItem: {marginLeft: "auto"}}}>
        <GetActions />
      </CardActions>
    </Card>
  );
}