import axios from "axios";
import { isAuthenticated, getAccessToken } from './session'

// const baseUrl = 'https://sbsdevapi.mirobotic.tech/';
// const baseUrl = 'https://sbstransit-api.mirobotic.tech/';
// const baseUrl = 'http://127.0.0.1:8000/';
const baseUrl = 'https://category-api.mirobotic.tech/';

const apiUrl = {
  baseUrl: `${baseUrl}`,
  account: `${baseUrl}account`,
  signIn: `${baseUrl}account/sign-in/`,
  signUp: `${baseUrl}account/sign-up/`,
  bus: `${baseUrl}settings/bus/`,
  deleteFile: `${baseUrl}record/delete/`,
  recordings: `${baseUrl}record/list/`,
  recordingStatus: `${baseUrl}record/status/`,
  recordingStart: `${baseUrl}record/start/`,
  recordingStop: `${baseUrl}record/stop/`,
  recordingUrl: `${baseUrl}record/cctv-record/`,
  birth: `${baseUrl}settings/berth/`,
  announceMedia: `${baseUrl}announce`,
  announcement: `${baseUrl}announce/announcement/`,
  greeting: `${baseUrl}announce/greeting/`,
  delay: `${baseUrl}announce/delay/`,
  schedule: `${baseUrl}record/schedule/`,

  contentMedia: `${baseUrl}content`,
  category: `${baseUrl}content/category/`,
  subCategory: `${baseUrl}content/sub-category/`,

  locations: `${baseUrl}temi/locations/`,
  notifyDelay: `${baseUrl}temi/notify-delay/`,
  notifyAnnounce: `${baseUrl}temi/notify-announce/`,
  forgotPassword: `${baseUrl}account/forgot-password/`,
  changePassword: `${baseUrl}account/change-password/`,
  resetPassword: `${baseUrl}account/reset-password/`,
  dashboard: `${baseUrl}account/dashboard/`,
  activate: `${baseUrl}account/activate/`,
  profile: `${baseUrl}account/profile/`,
  firmInfo: `${baseUrl}account/info/`,
  verifyActivateToken: `${baseUrl}account/verify/`,
  verifyResetToken: `${baseUrl}account/verify-reset/`,
  staff: `${baseUrl}account/staff/`,
  logo: `${baseUrl}account/logo/`,
};

export { baseUrl, apiUrl };

export function apiCallUnsecureGet(api, response, error) {
  console.log('apiCallSecureGet ', api);
  axios
    .get(api)
    .then((res) => {
      console.warn('result', res)
      const result = res.data;
      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }
    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log(err.config);
    });

}

export function apiCallSecureGet(api, response, error) {

  console.log('apiCallSecureGet ', api);
  axios
    .get(api,
      {
        headers: {
          'Authorization': `Token ${getAccessToken()}`
        }
      }
    )
    .then((res) => {
      console.warn('result', res)
      const result = res.data;
      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }
    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log(err.config);
    });

}

export function apiCallPostWithForm(api, form, response, error) {

  console.log('uploadFile ', api);

  axios.post(api, form, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Token ${getAccessToken()}`
      },
    })
    .then((res) => {
      console.warn('result', res)
      const result = res.data;
      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }
    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log(err.config);
    });
};

export function apiCallPutWithForm(api, form, response, error) {

  console.log('uploadFile ', api);

  axios.put(api, form, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Token ${getAccessToken()}`
      },
    })
    .then((res) => {
      console.warn('result', res)
      const result = res.data;
      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }
    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log('Error', err.response);
    });
};

export function uploadFile(api, name, file, response, error) {

  console.log('uploadFile ', api);
  console.log(name, file.name);

  const formData = new FormData();
  formData.append(name, file);
  axios.post(api, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Token ${getAccessToken()}`
      },
    })
    .then((res) => {
      console.warn('result', res)
      const result = res.data;
      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }
    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log(err.config);
    });
};

export function apiCallPostFormData(api, formData, response, error) {

  console.log('apiCallPostFormData ', api);

  axios.post(api, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': `Token ${getAccessToken()}`
      },
    })
    .then((res) => {
      console.warn('result', res)
      const result = res.data;
      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }
    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log(err.config);
    });
};

export function apiCallSecureDelete(api, id, response, error) {

  const url = `${api}?id=${id}`;
  console.log('apiCallSecureDelete ', url);
  axios
    .delete(url,
      {
        headers: {
          'Authorization': `Token ${getAccessToken()}`
        }
      }
    )
    .then((res) => {
      console.warn('result', res)
      const result = res.data;
      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }
    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log(err.config);
    });

}


export function apiPostUnsecure(api, data, response, error) {

  console.log('apiPostUnsecure ', api, 'data ', data);

  axios
    .post(api, data)
    .then((res) => {
      console.warn('result', res)

      const result = res.data;

      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }

    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log(err.config);
    });
}


export function apiPostSecure(api, data, response, onError) {

  console.log('apiPostSecure ', api, 'data ', data);

  axios
    .post(
      api,
      data,
      {
        headers: {
          'Authorization': `Token ${getAccessToken()}`
        }
      }
    )
    .then((res) => {
      console.log('result', res.data)

      const result = res.data;

      if (result.code === 200) {
        response(result)
      } else {
        onError(result.msg)
      }

    })
    .catch((err) => {
      try {

        console.log('error', err.response)

        if (err.response) {
          onError(err.response.data.msg)
        } else if (err.request) {
          console.log(err.request);
          onError(err.request)
        } else {
          console.log('Error', err.message);
          onError(`Error ${err.message}`)
        }
      } catch (e) {
        console.log(e);
        onError('Something went wrong!')
      }
      console.log(err.config);
    });
}

export function apiPutSecure(api, data, response, error) {

  console.log('apiPutSecure ', api, 'data ', data);

  axios
    .put(
      api,
      data,
      {
        headers: {
          'Authorization': `Token ${getAccessToken()}`
        }
      }
    )
    .then((res) => {
      console.warn('result', res)

      const result = res.data;

      if (result.code === 200) {
        response(result)
      } else {
        error(result.msg)
      }

    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        console.log(err.request);
        error(err.request)
      } else {
        console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      console.log(err.config);
    });
}

export default apiCallUnsecureGet;