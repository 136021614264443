import * as React from 'react';
import { useForm } from "react-hook-form";

import {
    styled,
    Box,
    Grid,
    Stack,
    Button,
    Container,
    Typography,
    FormControl,
    CssBaseline,
    TextField,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
  } from '@mui/material';

import { ErrorMessage } from '@hookform/error-message';
import { apiCallPostFormData, apiUrl } from '../../../../utils/api';
import { getNameFromPath } from '../../../../utils/ext';

const Input = styled('input')({
    display: 'none',
  });


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

  
export const AddCategoryModal = (props) => {

    const { show, change, state, onSaveChange } = props;

    const [addApiCalling, setAddApiCalling] = React.useState(false);
    const [mediaImage, setMediaImage] = React.useState(null);
    const [title, setTitle] = React.useState()

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        reset({
            id:null,
            text: "",
          }
        );
        change(false);
    }

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        clearErrors,
        reset,
        formState: { errors },
      } = useForm();

    const showFormError = (name, error) => {
        setError(name, {
            type: "server",
            message: error
        });
        setTimeout(() => {
            clearErrors();
        }, 3000);
    }  

    React.useEffect(() => {
        
        if(show) {
            setAddApiCalling(false)
            if(state.current) {
                setValue('id', state.current?.id)
                setValue('name', state.current?.name)
                setValue('description', state.current?.description)
                setMediaImage(state.current?.image)
            }
        }else {
            setValue('id', null)
            setValue('name', null)
            setValue('description', null)
            setMediaImage(null)
        }

    }, [show])

    const saveData = (data) => {
        
        console.log('data', data);

        setTimeout(() => {
            clearErrors();
        }, 3000);

        console.log('addApiCalling', addApiCalling);

        if(addApiCalling) {
            return
        }

        const formData = new FormData();
        
        if(mediaImage == null) {
            if(!state.current?.image) {
                showFormError('photo', "Category image required")
                return
            }
        }
       
        console.log('photo', mediaImage);
        
        if(data.id) {
            formData.append('id', data.id);
        }
        
        formData.append('name', data.name);
        formData.append('photo', mediaImage);
        formData.append('description', data.description);
      
        onSaveChange(0, "Saving category...");

        setAddApiCalling(true)

        apiCallPostFormData(apiUrl.category, formData,
            (response) => {
                setAddApiCalling(false)
                onSaveChange(1, response.msg);
                handleClose();
           },
           (errorMsg) => {
                setAddApiCalling(false)
                onSaveChange(2,  errorMsg||'Error');
                console.log('add-schedule', errorMsg||'Error');
           }
          )

    } 

    const onRemoveImage = () => {
        setMediaImage(null);
        const data = state.current;
        if(data) {
            data.image = null
            state.current = data;
        }
    }

    const onImageSelect = (value) => {
        console.log('logo', value);
        const file = value.target.files[0];
        if (file && !file.name) {
            showFormError('photo', 'Please select image file.');
           return false;
        }
        console.log('logo size', file.size);

        if (file.size > 1e6) {
            showFormError('photo', 'Please upload a image smaller than 1 MB');
            return false;
        }
        setMediaImage(file)
    }

    const getImageName = () => {
        if(mediaImage?.name) {
            return  mediaImage?.name
        }
        if(mediaImage) {
            return  getNameFromPath(mediaImage)
        }
        return null
    }

    const GetImageMedia = () => {
        if(mediaImage) {
            return (
                <Stack sx={{mt:2}} direction="row" alignItems="left" spacing={2}>
                    <Button variant="outlined" component="span" sx={{ width: '180px'}} onClick={() => onRemoveImage()} >
                        Change Image
                    </Button>
                    <Typography sx={{  mt: 1}} variant='body'>{getImageName()}</Typography>
                </Stack>
            )
        }
        return (
            <Stack sx={{mt:2}} direction="row" alignItems="left" spacing={2}>
                <FormControl>
                    <label htmlFor="contained-button-file">
                        <Input  accept="image/*" id="contained-button-file" multiple type="file" onChange={onImageSelect}/>
                        <Button sx={{ width: '180px'}}  variant="outlined" component="span" >
                            Select Image
                        </Button>
                    </label>
                </FormControl>
            </Stack>
        )
    }

    return (
        <Dialog open={show} onClose={handleClose}>
        <DialogTitle>Add Category</DialogTitle>
        <DialogContent>
            <form id="save-form" onSubmit={handleSubmit(saveData)}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                    >
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <Typography variant='body'>Photo</Typography>
                                    <GetImageMedia />     
                                    {errors.photo && <p>{errors.photo.message}</p>}    
                                </Stack> 
                            </Grid>  
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <Typography variant='body'>Name</Typography>
                                    <TextField type="text" placeholder="Name"  {...register("name",  { required: "Category name required!"})} /> 
                                    {errors.name && <p>{errors.name.message}</p>}  
                                </Stack>   
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <Typography variant='body'>Description</Typography>
                                    <TextField type="text" placeholder="Description"  {...register("description",  { required: "Category description required!"} )} /> 
                                    {errors.description && <p>{errors.description.message}</p>}    
                                </Stack> 
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage
                                    errors={errors}
                                    name="singleErrorInput"
                                    render={({ message }) => <p>{message}</p>}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    </Box>
                </Container>
            </form>  
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" onClick={() => handleClose()}>Close</Button>
            <Button variant="contained" type='submit'  form="save-form">Save</Button>
        </DialogActions>
    </Dialog>
    )
  }

export default AddCategoryModal;
