// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

 const getMenuOptions = (role) => {

  if(role === 'A') {
    return [
      {
        title: 'Dashboard',
        path: '/dashboard/app',
        icon: getIcon('eva:pie-chart-2-fill'),
      },
      // {
      //   title: 'Announcement',
      //   path: '/dashboard/announcement',
      //   icon: getIcon('mdi:announcement'),
      // },
      // {
      //   title: 'Greetings',
      //   path: '/dashboard/greetings',
      //   icon: getIcon('mdi:robot-excited'),
      // },
      // {
      //   title: 'Delays',
      //   path: '/dashboard/delays',
      //   icon: getIcon('mdi:bus-clock'),
      // },
      {
        title: 'Categories',
        path: '/dashboard/categories',
        icon: getIcon('material-symbols:category'),
      },
      {
        title: 'Sub Categories',
        path: '/dashboard/sub-categories',
        icon: getIcon('material-symbols:grid-view'),
      },
      // {
      //   title: 'Facility',
      //   path: '/dashboard/facility',
      //   icon: getIcon('mdi:map-marker-circle'),
      // },
    ];
  }

  if(role === 'U') {
    return [
      {
        title: 'Dashboard',
        path: '/dashboard/app',
        icon: getIcon('eva:pie-chart-2-fill'),
      },
      // {
      //   title: 'Announcement',
      //   path: '/dashboard/announcement',
      //   icon: getIcon('mdi:announcement'),
      // },
      {
        title: 'Schedule',
        path: '/dashboard/schedule',
        icon: getIcon('material-symbols:schedule-rounded'),
      },
      // {
      //   title: 'Facility',
      //   path: '/dashboard/facility',
      //   icon: getIcon('mdi:map-marker-circle'),
      // },
    ];
  }

  return [];

}

const mainSideBarData = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Programs',
    path: '/dashboard/programs',
    icon: getIcon('ant-design:robot-filled'),
  },
  {
    title: 'Students',
    path: '/dashboard/students',
    icon: getIcon('bxs:user'),
  },
  {
    title: 'Staff',
    path: '/dashboard/staff',
    icon: getIcon('wpf:administrator'),
  },
];

export const getSettings = (role) => {
  if(role === 'T' || role === 'A') {
    return [
      {
        title: 'Account',
        path: '/dashboard/account',
        icon: getIcon('material-symbols:admin-panel-settings-rounded'),
      },
      // {
      //   title: 'Settings',
      //   path: '/dashboard/settings',
      //   icon: getIcon('material-symbols:settings-applications-rounded'),
      // }
    ];
  }

  return [
    {
      title: 'Account',
      path: '/dashboard/account',
      icon: getIcon('bxs:business'),
    }
  ];
}

const settingsSideBarData = [
  {
    title: 'Account',
    path: '/dashboard/profile',
    icon: getIcon('material-symbols:admin-panel-settings-rounded'),
  },
  {
    title: 'Settings',
    path: '/dashboard/classes',
    icon: getIcon('bxs:group'),
  }
];

export default getMenuOptions
/*
  {
    title: 'Departments',
    path: '/dashboard/departments',
    icon: getIcon('fa6-solid:map-location-dot'),
  }
  {
    title: 'Hosts',
    path: '/dashboard/hosts',
    icon: getIcon('eva:person-done-fill'),
  },
  {
    title: 'Robots',
    path: '/dashboard/robots',
    icon: getIcon('ant-design:robot-filled'),
  },
  */